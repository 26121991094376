import { TextInput as MantineTextInput } from "@mantine/core";
import * as classes from "./TextInput.css";

export function TextInput(
  props: React.ComponentProps<typeof MantineTextInput>
) {
  const conditionalProps: {
    value?: string | number | readonly string[] | undefined;
  } = {};

  if (props.onChange) {
    conditionalProps.value = props.value ?? "";
  }

  return (
    <MantineTextInput
      {...props}
      {...conditionalProps}
      classNames={{
        input: classes.input,
        section: classes.section,
      }}
    />
  );
}
